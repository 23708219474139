import React, { useState, useEffect } from 'react'
import { Link, useStaticQuery } from "gatsby"
import axios from 'axios'
import '../scss/autocheck.scss'

import Layout from '../components/layout'

const staticQuery = graphql`
query  {
  allInventoryJson {
    nodes {
      VIN
      Pricing {
        Cost
        ExtraPrice1
        ExtraPrice2
        List
        ExtraPrice3
        Special
      }
      VehicleInfo {
        VIN
        IsNew
        BodyType
        Make
        Model
        Year
        VehicleStatus
      }
      ListOfPhotos {
        Order
        PhotoUrl
      }
    }
  }
}
`

const AutoCheck = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isVehicle, setIsVehicle] = useState(false)
  const [html, setHtml] = useState('')
  const { allInventoryJson } = useStaticQuery(staticQuery);
  const vehicles = allInventoryJson.nodes;
  const { pageContext } = props
  const { autocheck_url, site_id } = pageContext
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const vin = searchParams.get('vin')
    const apiUrl = `${autocheck_url}?vin=${vin}-${site_id}`
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, {timeout: 2000})
        setHtml(response.data)
        setIsVehicle(true)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (vehicles.some(vehicle => vehicle.VIN === vin)) {
        fetchData()
    } else setIsLoading(false)

    if(!isVehicle){
      window.location = window.location.origin
    }
  }, [])

  return (
      <div>
      {isLoading ? (
        <Layout seo={{seo:{MetaTitle:"Page Loading", MetaDesc:"The page is Loading.",tags:[]}}}>
          <div style={{height:"calc(100vh - 158px)", background: "var(--primary-color)", padding: "3em"}}>
              <h1 className="notFoundTitle" style={{color: "var(--primary-accent)", fontSize: "5em", fontFamily: '"Overpass", sans-serif', fontWeight: "200", textTransform: "uppercase"}}>Loading...</h1>
          </div>
        </Layout>
      ) : isVehicle ? (
        <Layout seo={{seo:{MetaTitle:"AutoCheck Report", MetaDesc:"The page is displaying the report.",tags:[]}}}>
          <iframe className='autocheck-report' srcDoc={html} allowfullscreen="" height={1200} style={{ width: '100%' }}></iframe>
        </Layout>
      ) : <Layout seo={{seo:{MetaTitle:"Report Not Found", MetaDesc:"The report you are looking for was not found.",tags:[]}}}>
            <div style={{height:"calc(100vh - 158px)", background: "var(--primary-color)", padding: "3em"}}>
                <h1 className="notFoundTitle" style={{color: "var(--primary-accent)", fontSize: "5em", fontFamily: '"Overpass", sans-serif', fontWeight: "200", textTransform: "uppercase"}}>Report Not Found</h1>
                <p className="notFoundText" style={{color: "var(--primary-text)", fontSize: "2em", fontFamily: '"Overpass", sans-serif', fontWeight: "700", textTransform: "uppercase"}}>The report you are looking for couldn&apos;t be found. Visit the <Link to={"/"} >homepage</Link>. </p>
            </div>
        </Layout>}
    </div>
  )
}

export default AutoCheck
